import { createAction, props } from '@ngrx/store';
import { PaymentAuthResponse } from 'src/app/core/api/endpoints/payment-auth';
import { Response } from 'src/app/core/api/api.interface';
import { ComplementaryPaymentMethod } from '../../payment/payment.state';

export enum InternalPaymentActions {
  handleZeroValuePaymentAuthResponse = '[Internal Payment Component] Handle Zero Value Payment Auth Response',
  handleZeroPaymentAuthError = '[Internal Payment Component] Handle Zero Payment Auth Error',
}

export const handleZeroValuePaymentAuthResponse = createAction(
  InternalPaymentActions.handleZeroValuePaymentAuthResponse,
  props<{ res: Response<PaymentAuthResponse>; paymentMethod: ComplementaryPaymentMethod }>()
);

export const handleZeroPaymentAuthError = createAction(InternalPaymentActions.handleZeroPaymentAuthError, props<{ error?: string }>());
